<template>
  <v-toolbar
      flat
      class="blood-transfusion-toolbar"
      height="auto"
  >
    <v-layout
        row
        wrap
    >
      <v-toolbar-items class="mb-2 mt-2">
        <date-range-picker-with-periods
            v-model="dateRange"
            label="Incident Date"
            class="mr-5"
            position="right"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.sequenceNumber"
            label="Sequence Number"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.incidentNumber"
            label="Incident Number"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
      <v-toolbar-items class="mb-2 mt-2">
        <v-text-field
            v-model="filtersModel.ptLastName"
            label="Pt. Last Name"
            hide-details
            class="mr-5"
        />
      </v-toolbar-items>
    </v-layout>

    <v-btn
        :loading="loadingExport"
        :disabled="exportDisabled"
        color="primary"
        @click="$emit('clickSelectiveExport')"
    >
      Export
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/actions';
import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';
import _ from 'lodash';

export default {
  name: 'BloodTransfusionToolbar',
  components: { DateRangePickerWithPeriods },

  props: {
    filters: {
      type: Object,
      required: true,
    },
    loadingExport: {
      type: Boolean,
      required: true,
    },
    exportDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dateRange: null,
      filtersModel: this.filters,
    };
  },

  watch: {
    dateRange: {
      handler() {
        const filters = _.cloneDeep(this.filtersModel);
        if (this.dateRange && this.dateRange.length === 2) {
          const [dateFrom, dateTo] = this.dateRange;
          filters.dateRangeFrom = dateFrom;
          filters.dateRangeTo = dateTo;
        }
        if (!this.dateRange || this.dateRange.length !== 2) {
          filters.dateRangeFrom = null;
          filters.dateRangeTo = null;
        }
        this.filtersModel = filters;
      },
      deep: true,
    },
    filtersModel: {
      handler() {
        this.$emit('update:filters', this.filtersModel);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),
  },
};
</script>

<style lang="scss">
.blood-transfusion-toolbar {
  z-index: 101;
}
</style>

<style lang="scss" module>
.date-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #eee;
  padding-top: 15px;
  padding-left: 15px;
}
</style>
