<template>
  <div>
    <v-toolbar
      class="search-results-tab"
      flat
    >
      <v-subheader
        v-if="entity.totalItems"
        class="subheader pl-0"
      >
        <span
          class="title"
        >Search Results:</span> {{ entity.totalItems }}
      </v-subheader>
      <v-spacer></v-spacer>
      <template v-if="entity.columnPresets && entity.columnPresets.length">
        <span class="mr-5">Column Preset: </span>
        <v-autocomplete
          v-model="preset"
          :items="presetsItems"
          item-value="id"
          item-text="name"
          hide-details
          style="max-width: 300px"
          @change="preset ? setSelectedFields(presetById[preset].data.selected, preset) : null"
        />
      </template>
      <column-manager
          ref="columnManager"
          :type="type"
          :all-fields="entity.tableFields || []"
          :selected="entity.selectedFields"
          :loading="externalLoading"
          :preset="presetById[preset]"
          @applied="setSelectedFields"
      />
      <v-btn
          class="ml-5"
          :loading="loadingExportPopup"
          :disabled="!entity.items || entity.items.length === 0"
          @click="showExport"
      >
        Export
      </v-btn>
      <selective-export
          ref="selectiveExport"
          :type="type"
          :all-fields="entity.exportFields"
          :selected="entity.selectedFields"
          :exporting="exporting"
          @export="exportResult"
      />
    </v-toolbar>
    <incidents-table
        :items="entity.items || []"
        :pagination="entity.pagination"
        :total-items="entity.totalItems"
        :table-fields="entity.tableFields || []"
        :selected-fields="entity.selectedFields"
        :loading="loading || externalLoading"
        :is-deleted-incidents-type="type === searchTypes.DELETED"
        :is-unfinished-incidents-type="type === searchTypes.UNFINISHED"
        :show-flagged-incidents-columns="type === searchTypes.FLAGGED"
        :show-view-incident-link="![searchTypes.DELETED, searchTypes.UNFINISHED].includes(type)"
        :header-indent="headerIndent"
        @update:pagination="updatePagination"
        @add-flag="addFlag"
        @incident-deleted="$emit('incident-deleted')"
        @commit-to-main-db="(incident) => {$emit('commit-to-main-db', incident)}"
        @overwrite-incident="(incident) => {$emit('overwrite-incident', incident)}"
        @view-incident="viewIncidentHandle"
        @ap-converted="apConverted"
    />
    <confirmation ref="confirmationPopup"/>
    <export-error-message ref="exportErrorMessagePopup"/>
  </div>
</template>
<script>
import SEARCH_TYPES from '@/enums/searchTypes';
import IncidentsTable from '@/components/Incidents/IncidentsTable';
import {
  SET_PAGINATION,
  SET_SELECTED_FIELDS,
  SET_CURRENT_COLUMN_PRESET,
} from '@/store/Incidents/mutations';
import { LOAD_INCIDENTS } from '@/store/Incidents/actions';
import _ from 'lodash';
import ColumnManager from '@/components/Incidents/ColumnManager';
import SelectiveExport from '@/components/Incidents/SelectiveExport';
import Confirmation from '@/components/Confirmation';
import ExportErrorMessage from '@/components/Incidents/ExportErrorMessage';
import selectiveExportMixins from '@/mixins/selectiveExportMixins';

export default {
  mixins: [selectiveExportMixins],
  components: {
    Confirmation, SelectiveExport, ColumnManager, IncidentsTable, ExportErrorMessage,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    externalLoading: {
      type: Boolean,
      required: true,
    },
    headerIndent: {
      type: Number,
      default: 450,
    },
  },
  data() {
    return {
      loading: false,
      searchTypes: SEARCH_TYPES,
    };
  },

  computed: {
    entity() {
      return this.$store.state.incidents[this.type];
    },
    exportFields() {
      return this.entity && this.entity.exportFields ? this.entity.exportFields : [];
    },
    exportPresets() {
      return this.entity.exportPresets;
    },
    totalItems() {
      return this.entity.totalItems;
    },
    preset: {
      get() {
        return this.entity.currentColumnPreset;
      },
      set(value) {
        this.setCurrentColumnPreset({
          value,
        });
      },
    },
    presetById() {
      const items = {};
      if (this.entity.columnPresets) {
        this.entity.columnPresets.forEach(preset => {
          items[preset.id] = preset;
        });
      }
      return items;
    },
    presetsItems() {
      const items = [{
        id: null,
        name: 'Custom',
      }];
      if (this.entity.columnPresets) {
        this.entity.columnPresets.forEach(presetItem => {
          const item = _.clone(presetItem);

          if (item.isCollaborate) {
            item.name = `${presetItem.name} (Collaborated)`;
          }

          items.push(item);
        });
      }
      return items;
    },
  },

  created() {
    if (this.entity.items === null) {
      this.reloadItems();
    }
  },

  methods: {
    setPagination(pagination) {
      this.$store.commit(`incidents/${this.type}/${SET_PAGINATION}`, pagination);
    },
    setFields(fields) {
      this.$store.commit(`incidents/${this.type}/${SET_SELECTED_FIELDS}`, fields);
    },
    setCurrentColumnPreset(payload) {
      this.$store.commit(`incidents/${this.type}/${SET_CURRENT_COLUMN_PRESET}`, payload);
    },
    loadIncidents(type) {
      return this.$store.dispatch(`incidents/${this.type}/${LOAD_INCIDENTS}`, type);
    },

    updatePagination(value) {
      if (!_.isEqual(value, this.entity.pagination)) {
        this.setPagination(value);
        this.reloadItems();
      }
    },

    apConverted() {
      this.reloadItems();
    },

    setSelectedFields(fields, preset) {
      this.setFields(fields);
      this.preset = preset;
      this.reloadItems();
    },

    /**
     * Reloads paginated list for current filter.
     */
    async reloadItems() {
      this.loading = true;
      try {
        await this.loadIncidents(this.type);
      } finally {
        this.loading = false;
      }
    },

    viewIncidentHandle(incident) {
      this.$emit('view-incident', incident, this.type);
    },

    addFlag(incident) {
      this.$emit('add-flag', incident);
    },
  },
};
</script>

<style lang="scss">
.search-results-tab {
  .subheader {
    font-size: 18px;
    .title {
      font-weight: 500;
      margin-right: 8px;
    }
  }
}
</style>
