<template>
  <v-container
    grid-list-xl
    fluid
>
    <v-layout
      row
      wrap
>
      <v-flex
        xl2
      >
        <v-text-field
          v-model="filters.search"
          :label="$t('devices.filters.search')"
          clearable
        />
      </v-flex>
      <v-flex
        xl2
      >
        <v-select
          v-model="filters.status"
          :items="statuses"
          :label="$t('devices.filters.status')"
          item-value="status"
          item-text="name"
          single-line
        />
      </v-flex>
      <v-flex
        xl2
      >
        <v-select
          v-model="filters.type"
          :items="types"
          :label="$t('devices.filters.type')"
          item-value="type"
          item-text="name"
          single-line
        />
      </v-flex>
      <v-flex
        xl2
      >
        <v-checkbox
          v-model="filters.isAPDevice"
          :label="$t('devices.filters.isAPDevice')"
        />
      </v-flex>
      <v-flex
        class="xl4"
        xl6
        text-lg-right
      >
        <v-btn
          :loading="exportLoading"
          color="primary"
          dark
          class="mb-2 mr-2"
          @click.native="$emit('exportToExcel')"
        >
          {{ $t('buttons.export') }}
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="$emit('addDevice')"
        >
          {{ $t('buttons.add') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DEVICE_TYPES from '@/enums/deviceTypes';
import DEVICE_TYPE_NAMES from '@/enums/deviceTypeNames';

export default {
  name: 'DevicesToolbar',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      types: [
        {
          type: null,
          name: this.$t('devices.filters.all'),
        },
        {
          type: DEVICE_TYPES.ALS,
          name: DEVICE_TYPE_NAMES.ALS,
        },
        {
          type: DEVICE_TYPES.BLS,
          name: DEVICE_TYPE_NAMES.BLS,
        },
        {
          type: DEVICE_TYPES.HELI,
          name: DEVICE_TYPE_NAMES.HELI,
        },
      ],
      statuses: [
        {
          status: 'all',
          name: this.$t('devices.filters.statuses.all'),
        },
        {
          status: 'active',
          name: this.$t('devices.filters.statuses.active'),
        },
        {
          status: 'deleted',
          name: this.$t('devices.filters.statuses.deleted'),
        },
      ],
    };
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search;
    }
  },
};
</script>
