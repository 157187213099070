import incidents from '@/api/incidents';
import {
  SET_INCIDENT_FIELDS, SET_INCIDENT,
  SET_TRANSMISSION_HISTORY,
} from '@/store/IncidentView/mutations';

export const LOAD_INCIDENT_FIELDS = 'LOAD_INCIDENT_FIELDS';
export const LOAD_INCIDENT = 'LOAD_INCIDENT';
export const LOAD_TRANSMISSION_HISTORY = 'LOAD_TRANSMISSION_HISTORY';
export const RELOAD_FLAG_INFO = 'RELOAD_FLAG_INFO';
export const RELOAD_INCIDENT_AFTER_SAVE = 'RELOAD_INCIDENT_AFTER_SAVE';

export default {
  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {number} incidentId - Incident identifier.
   *
   * @return {Promise}
   */
  [LOAD_INCIDENT_FIELDS]: async ({ commit }, incidentId) => {
    const results = await incidents.fetchIncidentFields(incidentId);
    commit(SET_INCIDENT_FIELDS, results);
  },

  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {number} incidentId - Incident identifier.
   *
   * @return {Promise}
   */
  [LOAD_INCIDENT]: async ({ commit }, incidentId) => {
    const params = {
      include: 'data,flag,incidentAudits,bloodTransfusionSignature',
    };
    const results = await incidents.fetchIncident(incidentId, params);
    commit(SET_INCIDENT, results);
  },

  /**
   * Load incidents list.
   *
   * @param {Function} commit - State commit.
   * @param {number} incidentId - Incident identifier.
   *
   * @return {Promise}
   */
  [LOAD_TRANSMISSION_HISTORY]: async ({ commit }, incidentId) => {
    const results = await incidents.fetchTransmissionHistory(incidentId);
    commit(SET_TRANSMISSION_HISTORY, results);
  },

  /**
   * Reload incident flag info
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - State.
   * @param {number} incidentId - Type of incidents to search
   *
   * @return {Promise}
   */
  [RELOAD_FLAG_INFO]: async ({ commit, state }, incidentId) => {
    const params = {
      include: 'flag',
    };
    const { incident } = state;
    const results = await incidents.fetchIncident(incidentId, params);
    incident.flag = results.flag;
    commit(SET_INCIDENT, incident);
  },

  /**
   * Reload incident audits
   *
   * @param {Function} commit - State commit.
   * @param {Object} state - State.
   * @param {number} incidentId - Type of incidents to search
   *
   * @return {Promise}
   */
  [RELOAD_INCIDENT_AFTER_SAVE]: async ({ commit, state }, incidentId) => {
    const params = {
      include: 'incidentAudits,bloodTransfusionSignature',
    };
    const { incident } = state;
    const results = await incidents.fetchIncident(incidentId, params);
    incident.incidentAudits = results.incidentAudits;
    incident.bloodTransfusionSigned = results.bloodTransfusionSigned;
    incident.hasBloodTransfusion = results.hasBloodTransfusion;
    incident.bloodTransfusionSignature = results.bloodTransfusionSignature;
    commit(SET_INCIDENT, incident);
  },
};
