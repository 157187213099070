const SEARCH_CRITERIA = {
  eq: 'Equal',
  not_eq: 'Not Equal',
  range: 'Range',
  is_null: 'Is Null',
  like: 'Like',
  weight: 'Weight (Kg)',
  image_attached: 'Has Image',
  has_signature: 'Has Signature',
  bmv_used: 'BMV Used?',
  age: 'Age',
  insights_therapy: 'QA/QI Insights',
  insights_providerImpression: 'QA/QI Insights',
  insights_advancedAirways: 'QA/QI Insights',
  insights_meds: 'QA/QI Insights',
  insights_vitals: 'QA/QI Insights',
  insights_transport: 'QA/QI Insights',
  insights_transportByProviderImpressions: 'QA/QI Insights',
  pucc_form_is_filled: 'PUCC Form Is Filled',
  has_blood_transfusion: 'Has Blood Transfusion',
  blood_transfusion_signed: 'Physician Ack',
};

export default SEARCH_CRITERIA;
