<template>
  <v-dialog
      v-model="dialog"
      class="column-manager"
      max-width="800px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          :disabled="loading"
          class="ml-5"
          v-on="on"
      >
        <v-icon
            left
            dark
        >
          mdi-cog-outline
        </v-icon>
        Column manager
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <b>Manage columns</b>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            label="Search fields..."
            append-icon="search"
            dense
            hide-details
        ></v-text-field>
      </v-toolbar>
      <preset-manager
        ref="presetManager"
        title="Column Preset"
        :type="presetTypes.COLUMN"
        :presets="entity.columnPresets"
        :data="presetData"
        :blank-data="{
          selected: [],
          groupBy: fieldsGroupBy.VALUES,
          showHistoricalFields: this.showHistoricalFields,
        }"
        :pre-selected="preset ? preset.id : null"
        @selected="setPreset"
        @deleted="deletePresetHandler"
        @updated="updatePresetHandler"
        @added="addPresetHandler"
      />
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
        <fields-selector
          ref="fieldsSelector"
          :all-fields="allFields"
          :search="search"
          :show-historical-fields="showHistoricalFields"
          :group-by="groupBy"
          :locked="[
              incidentFields.SEQUENCE_NUMBER,
              incidentFields.INCIDENT_NUMBER,
              incidentFields.INCIDENT_DATE
          ]"
          v-bind:selected.sync="selectedClone"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
            v-model="showHistoricalFields"
            label="Show historical fields"
        ></v-checkbox>
        <span class="ml-5 mr-2">Group fields by:</span>
        <v-select
            v-model="groupBy"
            :items="[fieldsGroupBy.NONE, fieldsGroupBy.VALUES]"
            dense
            flat
            style="max-width: 100px;"
        ></v-select>
        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="apply()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import FIELDS_GROUP_BY from '@/enums/fieldsGroupBy';
import INCIDENT_FIELDS from '@/enums/incidentFields';
import PRESET_TYPES from '@/enums/presetTypes';
import FieldsSelector from '@/components/Incidents/FieldsSelector';
import PresetManager from '@/components/Incidents/Presets/PresetManager';
import { SET_COLUMN_PRESET } from '@/store/Incidents/mutations';

export default {
  components: { PresetManager, FieldsSelector },
  props: {
    type: {
      type: String,
      required: true,
    },
    allFields: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    preset: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      search: null,
      groupBy: FIELDS_GROUP_BY.VALUES,
      dialog: false,
      selectedClone: [],
      showHistoricalFields: false,
      fieldsGroupBy: FIELDS_GROUP_BY,
      incidentFields: INCIDENT_FIELDS,
      presetTypes: PRESET_TYPES,
    };
  },
  computed: {
    entity() {
      return this.$store.state.incidents[this.type];
    },
    /**
     * Returns preset data as one object
     *
     * @return {Object}
     */
    presetData() {
      return {
        selected: this.selectedClone,
        groupBy: this.groupBy,
        showHistoricalFields: this.showHistoricalFields,
      };
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.selectedClone = _.clone(this.selected);
        this.search = null;
        if (this.preset) {
          this.groupBy = this.preset.data.groupBy;
          this.showHistoricalFields = this.preset.data.showHistoricalFields;
          if (this.$refs.presetManager) {
            this.$refs.presetManager.set(this.preset.id);
          }
        }
      }
    },
  },

  methods: {
    setColumnPreset(presets) {
      this.$store.commit(`incidents/${this.type}/${SET_COLUMN_PRESET}`, presets);
    },

    /**
     * Handler for event when user selects preset.
     *
     * @param {Object} data - Data to set.
     */
    setPreset(data) {
      if (data) {
        this.selectedClone = _.clone(data.selected);
        this.groupBy = data.groupBy;
        this.showHistoricalFields = data.showHistoricalFields;
      } else {
        this.selectedClone = [];
        this.groupBy = FIELDS_GROUP_BY.VALUES;
        this.showHistoricalFields = false;
      }
    },

    /**
     * Handler for add preset
     *
     * @param {Object} preset - Preset object
     */
    addPresetHandler(preset) {
      const presets = this.entity.columnPresets;
      presets.push(preset);
      this.setColumnPreset(presets);
    },

    /**
     * Handler for delete preset
     *
     * @param {Object} preset - Preset object
     */
    deletePresetHandler(presetIndex) {
      const presets = _.clone(this.entity.columnPresets);
      presets.splice(presetIndex, 1);
      this.setColumnPreset(presets);
    },

    /**
     * Handler for update preset
     *
     * @param {Number} index - Preset index
     * @param {Object} preset - Preset object
     */
    updatePresetHandler(index, preset) {
      const presets = _.clone(this.entity.columnPresets);
      presets[index] = preset;
      this.setColumnPreset(presets);
    },

    /**
     * Apply selected fields.
     */
    apply() {
      const preset = this.$refs.presetManager.preset && !this.$refs.presetManager.isChanged
        ? this.$refs.presetManager.preset
        : null;
      this.$emit('applied', this.selectedClone, preset);
      this.dialog = false;
    },
  },
};
</script>
