export default {
  header: 'API Interactions Log',
  additionalFilters: 'Additional Filters',
  tableHeaders: {
    datetime: 'Date/Time',
    deviceInfo1: 'Device Info #1',
    deviceInfo2: 'Device Info #2',
    methodName: 'API Method',
    significantData: 'Details',
    status: 'Status',
  },
  filters: {
    deviceId: 'Device Id',
    dispatchId: 'Dispatch Id',
    inventoryNumber: 'Inventory Number',
    dateFrom: 'Date From',
    dateTo: 'Date To',
    significantData: 'Details',
    UDID: 'UDID',
  },
  items: {
    deviceID: 'Device ID',
    inventoryNumber: 'Inventory#',
    dispatchId: 'Dispatch ID',
    UDID: 'UDID',
  },
  statuses: {
    all: 'All Statuses',
    success: 'Success',
    fail: 'Fail',
  },
};
