<template>
  <v-card
    class="blood-transfusion-signature"
    :loading="loading || !scriptLoaded"
  >
    <v-toolbar flat>
      <v-icon
          dense
          class="mr-2"
      >mdi-draw-pen</v-icon>
      Blood Transfusion Signature
    </v-toolbar>
    <v-divider/>
    <v-card-text
        v-if="bloodTransfusionSigned"
        class="text-center"
    >
      <v-container class="d-flex flex-column align-center">
        <v-img
            v-if="bloodTransfusionSignature.thumbnail"
            :src="bloodTransfusionSignature.thumbnail"
            max-width="200"
            class="ma-auto mb-3"
            style="border: 1px #b0b0b0 solid; cursor: pointer;"
            @click="showDocument"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="gray"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-btn
            color="green"
            dark
            @click="showDocument"
        >
          Show Signed Document
        </v-btn>
      </v-container>

    </v-card-text>
    <v-card-text
        v-else
        class="text-center"
    >
      <span v-if="isWaitingForConfirmation">
        <v-progress-circular
            :size="30"
            color="primary"
            center
            indeterminate
        /><br />
        Waiting for document signature confirmation...
      </span>
      <v-btn
          v-else
          :loading="creatingDocument"
          :disabled="!scriptLoaded || waitingSignature || loading"
          color="primary"
          @click="createDocumentToSign"
      >
        <v-icon left>
          mdi-draw-pen
        </v-icon>
        Sign Document
      </v-btn>
    </v-card-text>
    <information ref="infoPopup"/>
    <blood-transfusion-sign-confirmation-popup
        ref="signConfirmation"
        @created="signDocument"
    />
  </v-card>
</template>

<script>
import bloodTransfusion from '@/api/bloodTransfusion';
import { mapActions } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/actions';
import Information from '@/components/Information';
import BloodTransfusionSignConfirmationPopup from '@/components/IncidentView/BloodTransfusionSignConfirmationPopup';

const scriptSrc = 'https://static.signwell.com/assets/embedded.js';
const loadScript = () => new Promise((resolve, reject) => {
  if (document.querySelector(`script[src="${scriptSrc}"]`)) {
    resolve(); // if the script is already loaded.
    return;
  }

  const script = document.createElement('script');
  script.src = scriptSrc;
  script.type = 'text/javascript';
  script.async = true;
  script.onload = resolve;
  script.onerror = reject;
  document.head.appendChild(script);
});

export default {
  name: 'BloodTransfusionSignature',
  components: { BloodTransfusionSignConfirmationPopup, Information },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    incidentId: {
      type: Number,
      required: true,
    },
    incidentData: {
      type: Object,
      required: true,
    },
    bloodTransfusionSigned: {
      type: Boolean,
      required: true,
    },
    bloodTransfusionSignature: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      scriptLoaded: false,
      creatingDocument: false,
      waitingSignature: false,
    };
  },
  computed: {
    isWaitingForConfirmation() {
      return this.bloodTransfusionSignature
          && this.bloodTransfusionSignature.status === 'signed';
    },
  },
  async mounted() {
    await this.loadSignWellScript();
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    setConfirmed() {
      this.waitingSignature = false;
    },

    async loadSignWellScript() {
      await loadScript();
      if (window.SignWellEmbed) {
        this.scriptLoaded = true;
      } else {
        console.error('SignWellEmbed is still undefined after script load');
      }
    },

    async showDocument() {
      const signWellEmbed = new window.SignWellEmbed({
        url: `https://www.signwell.com/app/preview/document/${this.bloodTransfusionSignature.documentId}/`,
        events: {},
      });
      signWellEmbed.open();
    },

    createDocumentToSign() {
      if (!this.checkBtFieldsCompleteness()) {
        return;
      }
      this.$refs.signConfirmation.show(this.incidentId);
    },

    async signDocument(data) {
      const documentData = data;
      try {
        this.creatingDocument = true;
        const signatureId = documentData.id;
        const signWellEmbed = new window.SignWellEmbed({
          url: documentData.signingUrl,
          events: {
            completed: async () => {
              await bloodTransfusion.setSigned(this.incidentId, signatureId);
              documentData.status = 'signed';
              this.$emit('documentCreated', documentData);
              this.waitingSignature = false;
            },
            closed: () => {
              this.waitingSignature = false;
            },
          },
        });
        signWellEmbed.open();
        this.waitingSignature = true;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.showSnackbar({
            title: e.response.data.message,
            color: 'primary',
          });
        }
      } finally {
        this.creatingDocument = false;
      }
    },

    checkBtFieldsCompleteness() {
      const requiredFields = [
        'BT_BloodProductIndication',
        'BT_TypeOfProcedure',
        'BT_BloodUnitNumber',
        'BT_DonorBloodType',
        'BT_ExpirationDate',
        'BT_PatientVerifiedByName1',
        'BT_PatientVerifiedByName2',
        'BT_TimeInitiated',
        'BT_TotalVolumeAdministered',
        'BT_UnitOfMeasurement',
        'BT_PartialUnits',
        'BT_AdministrationRoute',
        'BT_TimeCompleted',
        'BT_BloodProductReaction',
        'BT_CrewAdministeringName',
        'BT_TypeOfConsentObtained',
        'BT_ProductCode',
      ];
      let allIsFilled = true;
      requiredFields.forEach(field => {
        if (!this.incidentData[field]) {
          allIsFilled = false;
        }
      });

      if (!allIsFilled) {
        this.$emit('goToBloodTransfusion');
        this.$refs.infoPopup.showInfo('Attention', 'To sign the document, all fields in the Blood'
            + 'Transfusion section must be completed. Please check if all fields are filled out.');
      }
      return allIsFilled;
    },
  },
};
</script>

<style lang="scss">
.blood-transfusion-signature {
  .v-card__text {
    color: black !important;
  }
  .name {
    font-weight: 500;
  }
  .name-toolbar {
    .v-toolbar__content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
#SignWell-Modal-Embedded {
  z-index: 1000;
}

</style>
