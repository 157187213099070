<template>
  <div>
    <v-card
      flat
      class="mx-4"
    >
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="'tab_' + item.id"
            :tab-value="index"
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
        <v-spacer />

        <v-dialog
            v-model="showTooltip"
            width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mt-3 mr-5"
                small
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  left
                  dark
              >
                mdi-chat-question-outline
              </v-icon>
              {{ tabItems[tabIndex].questionButtonName }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ tabItems[tabIndex].questionButtonName }}</span>
            </v-card-title>
            <v-card-text v-html="tabItems[tabIndex].tooltip" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="showTooltip = false"
              >
                Got It
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tabs>
      <v-divider />
    </v-card>
    <v-tabs-items
      :value="tabIndex"
      class="transparent ml-4 mr-4"
    >
      <v-tab-item
        v-for="(item) in tabItems"
        :key="'tab_' + item.id"
      >
        <transmitted-reports
            v-if="item.id === incidentCloudTabs.AIRDROPPED"
            :type="transmittedTypes.AIRDROP"
        />
        <transmitted-reports
            v-if="item.id === incidentCloudTabs.TRANSMITTED"
            :type="transmittedTypes.REMOTE_HANDOFF"
        />
        <incidents
            v-if="item.id === incidentCloudTabs.UNFINISHED"
            :type="searchTypes.UNFINISHED"
            :header-indent="525"
        />
        <incidents
            v-if="item.id === incidentCloudTabs.DELETED"
            :type="searchTypes.DELETED"
            :header-indent="470"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import TransmittedReports from '@/components/IncidentsCloud/TransmittedReports';
import TRANSMITTED_TYPES from '@/enums/transmittedTypes';
import SEARCH_TYPES from '@/enums/searchTypes';
import Incidents from '@/components/Incidents/Incidents';
import INCIDENT_CLOUD_TABS from '@/enums/incidentCloudTabs';
import ROUTES from '@/enums/routes';
import navigationCheckAccessMixins from '@/mixins/navigationCheckAccessMixins';

export default {
  mixins: [navigationCheckAccessMixins],
  components: {
    Incidents,
    TransmittedReports,
  },

  props: {
    tab: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      tabIndex: 0,
      transmittedTypes: TRANSMITTED_TYPES,
      searchTypes: SEARCH_TYPES,
      incidentCloudTabs: INCIDENT_CLOUD_TABS,
      showTooltip: false,
      commonText: '<p>The <b>Incident Cloud</b> contains the most recently synced versions of incidents. '
          + 'Whether it is an <b>Unfinished Report</b>, <b>Deleted</b>, or a report that was <b>AirDropped/Transmitted</b>. '
          + 'Use the <b>Incident Cloud</b> to recover incidents “lost” due to a device issue or incidents from '
          + 'lost or stolen devices.</p>',
    };
  },
  watch: {
    tabIndex(value) {
      const tabId = this.tabItems[value].id;
      this.changeUrl(tabId);
    },
  },
  computed: {
    tabItems() {
      const items = [];
      const that = this;
      const allItems = [
        {
          id: this.incidentCloudTabs.DELETED,
          name: 'Deleted',
          questionButtonName: 'What are Deleted incidents?',
          tooltip: `${this.commonText}`
              + '<p>When a report is deleted in the ePCR app from the “<b>Currently Unfinished Form</b>” '
              + 'page, it will be removed from the device only after a copy has been uploaded to the cloud. '
              + 'All deleted reports are displayed on this page and can be restored to the main database '
              + 'using the <b>Commit to Main DB</b> function.</p>',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_CLOUD_DELETED,
            });
          },
        },
        {
          id: this.incidentCloudTabs.UNFINISHED,
          name: 'Unfinished',
          questionButtonName: 'What are Unfinished incidents?',
          tooltip: `${this.commonText}`
              + '<p>Whenever the app is idle and has internet access, all unfinished reports are uploaded '
              + 'to the cloud to create an additional backup on the server side. From this page, you can '
              + 'view a report, commit it to the main database, or delete it.</p>',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_CLOUD_UNFINISHED,
            });
          },
        },
        {
          id: this.incidentCloudTabs.TRANSMITTED,
          name: 'Transmitted',
          questionButtonName: 'What are Transmitted incidents?',
          tooltip: `${this.commonText}`
              + '<p>The ePCR app allows report sharing not only via AirDrop but also through <b>Remote Handoff.</b> '
              + 'In this case, the incident data from the source device is uploaded to the cloud, where '
              + 'it can later be retrieved by another device.</p>'
              + '<p>On this page, you can view reports currently stored in the cloud and manually assign '
              + 'devices that can receive a specific report.</p>',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_CLOUD_TRANSMITTED,
            });
          },
        },
        {
          id: this.incidentCloudTabs.AIRDROPPED,
          name: 'AirDropped',
          questionButtonName: 'What are AirDropped incidents?',
          tooltip: `${this.commonText}`
              + '<p>After successfully transmitting the report via <b>AirDrop</b>, the ePCR app prompts the user '
              + 'to delete the transferred report from the current device. However, before deleting '
              + 'the report data from the source device, the app uploads it to the cloud to ensure '
              + 'that the report can be retrieved in case of any issues.</p>',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_CLOUD_AIRDROPPED,
            });
          },
        },
      ];
      allItems.forEach(item => {
        if (item.isAvailable()) {
          items.push(item);
        }
      });
      return items;
    },
    tabItemsIdMap() {
      const map = {};
      this.tabItems.forEach((item, index) => {
        map[item.id] = index;
      });
      return map;
    },
  },
  async mounted() {
    this.$store.commit(SET_TITLE, 'Incident Cloud');
    if (this.tab) {
      this.tabIndex = this.tabItemsIdMap[this.tab];
    }
  },

  methods: {
    changeUrl(tabId) {
      const routesMap = {};
      routesMap[INCIDENT_CLOUD_TABS.AIRDROPPED] = ROUTES.INCIDENTS_CLOUD_AIRDROPPED;
      routesMap[INCIDENT_CLOUD_TABS.TRANSMITTED] = ROUTES.INCIDENTS_CLOUD_TRANSMITTED;
      routesMap[INCIDENT_CLOUD_TABS.UNFINISHED] = ROUTES.INCIDENTS_CLOUD_UNFINISHED;
      routesMap[INCIDENT_CLOUD_TABS.DELETED] = ROUTES.INCIDENTS_CLOUD_DELETED;
      const link = this.$router.resolve({ name: routesMap[tabId] }).href;
      window.history.replaceState({}, null, link);
    },
  },
};
</script>
