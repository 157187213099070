<template>
  <div>
    <v-card
        v-if="tabItems.length > 1"
        flat
        class="mx-4"
    >
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="'tab_' + item.id"
            :tab-value="index"
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider />
    </v-card>
    <v-tabs-items
        :value="tabIndex"
        class="transparent ml-4 mr-4"
    >
      <v-tab-item
          v-for="(item) in tabItems"
          :key="'tab_' + item.id"
      >
        <incidents
            v-if="item.id === incidentTabs.REGULAR"
            :type="searchTypes.REGULAR"
            :header-indent="tabItems.length > 1 ? 480 : 440"
        />
        <incidents
            v-if="item.id === incidentTabs.FLAGGED"
            :type="searchTypes.FLAGGED"
            :header-indent="480"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import SEARCH_TYPES from '@/enums/searchTypes';
import Incidents from '@/components/Incidents/Incidents';
import INCIDENT_TABS from '@/enums/incidentTabs';
import ROUTES from '@/enums/routes';
import navigationCheckAccessMixins from '@/mixins/navigationCheckAccessMixins';

export default {
  components: {
    Incidents,
  },

  mixins: [navigationCheckAccessMixins],

  props: {
    tab: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      tabIndex: 0,
      searchTypes: SEARCH_TYPES,
      incidentTabs: INCIDENT_TABS,
    };
  },
  watch: {
    tabIndex(value) {
      const tabId = this.tabItems[value].id;
      this.changeUrl(tabId);
    },
  },
  computed: {
    tabItems() {
      const items = [];
      const that = this;
      const allItems = [
        {
          id: this.incidentTabs.REGULAR,
          name: 'All Incidents',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_REGULAR,
            });
          },
        },
        {
          id: this.incidentTabs.FLAGGED,
          name: 'Flagged',
          isAvailable() {
            return that.haveAccess({
              route: ROUTES.INCIDENTS_FLAGGED,
            });
          },
        },
      ];
      allItems.forEach(item => {
        if (item.isAvailable()) {
          items.push(item);
        }
      });
      return items;
    },
    tabItemsIdMap() {
      const map = {};
      this.tabItems.forEach((item, index) => {
        map[item.id] = index;
      });
      return map;
    },
  },
  async mounted() {
    this.$store.commit(SET_TITLE, 'Incidents');
    if (this.tab) {
      this.tabIndex = this.tabItemsIdMap[this.tab];
    }
  },

  methods: {
    changeUrl(tabId) {
      const routesMap = {};
      routesMap[INCIDENT_TABS.REGULAR] = ROUTES.INCIDENTS_REGULAR;
      routesMap[INCIDENT_TABS.FLAGGED] = ROUTES.INCIDENTS_FLAGGED;
      const link = this.$router.resolve({ name: routesMap[tabId] }).href;
      const currentState = window.history.state ? window.history.state : {};
      window.history.replaceState(currentState, null, link);
    },
  },
};
</script>
