<template>
  <div>
    <v-card
      class="mb-4"
    >
      <transmitted-search-form
          ref="transmittedSearchForm"
          :sequenceNumber="sequenceNumber"
          @search="search"
      />
    </v-card>

    <v-card>
      <transmitted-reports-table
          ref="transmittedReportsTable"
          :type="type"
          :sequenceNumber="sequenceNumber"
          @reassignClick="reassignTransmitted"
      />
      <reassign-transmitted-report-popup
          ref="reassignTransmittedReportsPopup"
          @saved="$refs.transmittedReportsTable.reloadItems()"
      />
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { CLEAR_STATE } from '@/store/TransmittedReports/mutations';
import TransmittedReportsTable from '@/components/IncidentsCloud/TransmittedReports/TransmittedReportsTable';
import ReassignTransmittedReportPopup from '@/components/IncidentsCloud/TransmittedReports/ReassignTransmittedReportPopup';
import TransmittedSearchForm from '@/components/IncidentsCloud/TransmittedReports/TransmittedSearchForm';

export default {
  components: { ReassignTransmittedReportPopup, TransmittedReportsTable, TransmittedSearchForm },

  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sequenceNumber: null,
    };
  },

  created() {
    this.clearState();
    const sequenceNumber = this.$route.query.sequenceNumber ?? null;

    if (sequenceNumber !== null) {
      this.sequenceNumber = sequenceNumber;
    }
  },

  methods: {
    ...mapMutations({
      clearState: `transmittedReports/${CLEAR_STATE}`,
    }),

    /**
     * Reassign transmitted report
     *
     * @param {Object} transmittedReport - Transmitted report
     */
    reassignTransmitted(transmittedReport) {
      this.$refs.reassignTransmittedReportsPopup.show(transmittedReport);
    },

    /**
     * Search by sequence number.
     *
     * @param {String|null} sequenceNumber - Sequence number
     */
    search(sequenceNumber) {
      this.sequenceNumber = sequenceNumber;
      this.$refs.transmittedReportsTable.reloadItems(this.sequenceNumber);
      this.$refs.transmittedSearchForm.stopLoading();
    },
  },
};
</script>
