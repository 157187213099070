import actions from './actions';
import mutations from './mutations';
import defaultState from './defaultState';

export default {
  namespaced: true,
  modules: {
    regular: {
      namespaced: true,
      state: defaultState(),
      actions,
      mutations,
      getters: {},
    },
    flagged: {
      namespaced: true,
      state: defaultState(),
      actions,
      mutations,
      getters: {},
    },
    deleted: {
      namespaced: true,
      state: defaultState(),
      actions,
      mutations,
      getters: {},
    },
    unfinished: {
      namespaced: true,
      state: defaultState(),
      actions,
      mutations,
      getters: {},
    },
  },
};
