<template>
  <div>
    <v-card class="white elevation-3 ma-4">
      <blood-transfusion-toolbar
          :filters.sync="filters"
          :loading-export="loadingExportPopup"
          :export-disabled="totalItems === 0"
          @clickSelectiveExport="showExport"
      />
    </v-card>

    <v-card class="ma-4">
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="item.name"
            :tab-value="index"
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items :value="tabIndex">
        <v-tab-item eager>
          <blood-transfusion-table
              ref="unsigned"
              :signed="false"
              :filters="filters"
              @returnTotal="setUnsignedTotal"
          />
        </v-tab-item>
        <v-tab-item eager>
          <blood-transfusion-table
              ref="signed"
              :signed="true"
              :filters="filters"
              @returnTotal="setSignedTotal"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <selective-export
        ref="selectiveExport"
        :type="type"
        :all-fields="entity.exportFields"
        :selected="entity.selectedFields"
        :exporting="exporting"
        @export="exportResult"
    />
  </div>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import BloodTransfusionToolbar from '@/components/BloodTransfusion/BloodTransfusionToolbar';
import BloodTransfusionTable from '@/components/BloodTransfusion/BloodTransfusionTable';
import SelectiveExport from '@/components/Incidents/SelectiveExport';
import selectiveExportMixins from '@/mixins/selectiveExportMixins';
import SEARCH_TYPES from '@/enums/searchTypes';

export default {
  mixins: [selectiveExportMixins],
  components: {
    SelectiveExport,
    BloodTransfusionTable,
    BloodTransfusionToolbar,
  },

  data() {
    return {
      type: SEARCH_TYPES.REGULAR,
      tabIndex: 0,
      unsignedTotal: 0,
      signedTotal: 0,
      filters: {
        dateRangeFrom: null,
        dateRangeTo: null,
        sequenceNumber: null,
        incidentNumber: null,
        ptLastName: null,
      },
    };
  },
  computed: {
    entity() {
      return this.$store.state.incidents[this.type];
    },
    exportFields() {
      return this.entity && this.entity.exportFields ? this.entity.exportFields : [];
    },
    exportPresets() {
      return this.entity.exportPresets;
    },
    totalItems() {
      return this.isUnsignedTab ? this.unsignedTotal : this.signedTotal;
    },
    isUnsignedTab() {
      return this.tabIndex === 0;
    },
    tabItems() {
      return [
        {
          id: 'unsigned',
          name: `Unsigned${this.unsignedTotal ? ` (${this.unsignedTotal})` : ''}`,
        },
        {
          id: 'signed',
          name: `Signed${this.signedTotal ? ` (${this.signedTotal})` : ''}`,
        },
      ];
    },
  },

  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
    tabIndex() {
      this.setExportSearchFilters();
    },
  },

  async mounted() {
    this.$store.commit(SET_TITLE, 'Blood Transfusion to Sign');
    this.setExportSearchFilters();
  },

  methods: {
    /**
     * Fetch data for current view.
     */
    search() {
      this.$refs.unsigned.search();
      this.$refs.signed.search();
      this.setExportSearchFilters();
    },

    setExportSearchFilters() {
      const conditions = [];
      if (this.isUnsignedTab) {
        conditions.push({
          field: 'BloodTransfusionSigned',
          type: 'blood_transfusion_signed',
          signed: false,
        });
      } else {
        conditions.push({
          field: 'BloodTransfusionSigned',
          type: 'blood_transfusion_signed',
          signed: true,
        });
      }
      if (this.filters.dateRangeFrom && this.filters.dateRangeTo) {
        conditions.push({
          field: 'II_IncidentDate',
          type: 'range',
          gte: this.filters.dateRangeFrom,
          lte: this.filters.dateRangeTo,
        });
      }
      if (this.filters.sequenceNumber) {
        conditions.push({
          field: 'II_SequenceNumber',
          type: 'like',
          value: this.filters.sequenceNumber,
        });
      }
      if (this.filters.incidentNumber) {
        conditions.push({
          field: 'II_IncidentNumber',
          type: 'like',
          value: this.filters.incidentNumber,
        });
      }
      if (this.filters.ptLastName) {
        conditions.push({
          field: 'PI_LastName',
          type: 'like',
          value: this.filters.ptLastName,
        });
      }
      this.searchFilters = [
        {
          operator: 'and',
          conditions,
        },
      ];
    },

    updateIncidentLists() {
      this.$refs.unsigned.fetchData();
      this.$refs.signed.fetchData();
    },

    setUnsignedTotal(total) {
      this.unsignedTotal = total;
    },

    setSignedTotal(total) {
      this.signedTotal = total;
    },
  },
};
</script>
