<template>
  <div>
    <v-data-table
        :headers="header"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :height="tableHeight"
        :loading="loading"
        :footer-props="{
           itemsPerPageOptions: [10,25,50]
        }"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap system-search-table"
    >
      <template v-slot:item.dispatchId="{ item }">
        {{ item.dispatchId }} (Inventory #{{ item.inventoryNumber }})
      </template>
      <template v-slot:item.incidentDate="{ item }">
        {{ item.incidentDate | date }}
      </template>
      <template v-slot:item.sequenceNumber="{ item }">
        <a @click="$emit('goToDevice', item)">{{ item.sequenceNumber }}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import fileDownload from 'js-file-download';
import fixedHeader from '@/mixins/fixedHeader';
import { SHOW_SNACKBAR } from '@/store/actions';
import impersonateMixins from '@/mixins/impersonateMixins';

export default {
  name: 'OnDeviceIncidentsTable',
  mixins: [fixedHeader, impersonateMixins],
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
    };
  },
  computed: {
    header() {
      return [
        {
          text: 'Sequence Number',
          value: 'sequenceNumber',
          sortable: false,
        },
        {
          text: 'Device',
          value: 'dispatchId',
          sortable: false,
        },
        {
          text: 'Incident Date',
          value: 'incidentDate',
          sortable: false,
        },
        {
          text: 'Last Name',
          value: 'patientLastName',
          sortable: false,
        },
        {
          text: 'Client',
          value: 'client',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.$emit('updatePagination', newData);
      }
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Download JSON incident data.
     *
     * @param {Object} item Incident data
     */
    async downloadJson(item) {
      try {
        fileDownload(JSON.stringify(item), `${item.sequenceNumber}.json`);
      } catch (e) {
        await this.showSnackbar({ title: `Unexpected error while downloading JSON for ${item.sequenceNumber}`, color: 'red' });
      }
    },
  },
};
</script>
<style lang="scss">
  .system-search-table thead.v-data-table-header tr th{
    z-index: 1 !important;
  }
</style>
