<template>
  <v-dialog
    v-model="showInformPopup"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <slot/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="showInformPopup = false"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Information',
  data() {
    return {
      showInformPopup: false,
      title: null,
      text: null,
    };
  },
  methods: {
    /**
     * Show information popup
     *
     * @param {string} title - Title text of dialog.
     * @param {string} text - Message in confirmation popup.
     */
    showInfo(title, text) {
      this.showInformPopup = true;
      this.title = title;
      this.text = text;
    },
  },
};
</script>
