<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
      <v-card>
        <v-card-title>Sign Confirmation</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
              :value="errorAlert"
              type="error"
              outlined
              transition="scale-transition"
              class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>
          <div class="mt-3 mb-3">
            Please enter your current password to confirm the document signing:
          </div>
          <v-text-field
            v-model="password"
            v-validate="{ required: true }"
            name="password"
            :error-messages="errors.collect('password')"
            label="Your Password"
            type="password"
            maxlength="255"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              :loading="submitting"
              @click="clickSignHandler"
          >
            Sign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import errorAlertMixins from '@/mixins/errorAlertMixins';
import bloodTransfusion from '@/api/bloodTransfusion';

export default {
  name: 'BloodTransfusionSignConfirmationPopup',
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      incidentId: null,
      password: null,
      submitting: false,
      dictionary: {
        attributes: {
          password: 'Your Password',
        },
      },
    };
  },
  methods: {
    show(incidentId) {
      this.incidentId = incidentId;
      this.dialog = true;
      this.password = null;
    },
    hide() {
      this.dialog = false;
    },
    async clickSignHandler() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.submitting = true;
      try {
        const response = await bloodTransfusion.createDocumentToSign(
          this.incidentId,
          this.password,
        );
        const documentData = response.data;
        this.$emit('created', documentData);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response, true);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
