<template>
  <v-dialog
      v-model="dialog"
      class="column-manager"
      max-width="800px"
      persistent
  >
    <v-card>
      <v-toolbar flat>
        <b>Selective Export</b>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            label="Search fields..."
            append-icon="search"
            dense
            hide-details
        ></v-text-field>
      </v-toolbar>
      <preset-manager
          ref="presetManager"
          title="Export Preset"
          :type="presetTypes.EXPORT"
          :presets="entity.exportPresets"
          :data="presetData"
          :blank-data="{
          selected: [],
          groupBy: fieldsGroupBy.VALUES,
          showHistoricalFields: this.showHistoricalFields,
        }"
          @selected="setPreset"
          @deleted="deletePresetHandler"
          @updated="updatePresetHandler"
          @added="addPresetHandler"
      />
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
        <fields-selector
            ref="fieldsSelector"
            :all-fields="allFields"
            :search="search"
            :show-historical-fields="showHistoricalFields"
            :group-by="groupBy"
            :locked="[
              incidentFields.SEQUENCE_NUMBER,
              incidentFields.INCIDENT_NUMBER,
              incidentFields.INCIDENT_DATE
          ]"
            v-bind:selected.sync="selected"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
            v-model="showHistoricalFields"
            label="Show historical fields"
        ></v-checkbox>
        <span class="ml-5 mr-2">Group fields by:</span>
        <v-select
            v-model="groupBy"
            :items="[fieldsGroupBy.NONE, fieldsGroupBy.VALUES]"
            dense
            flat
            style="max-width: 100px;"
        ></v-select>
        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="exporting"
            text
            color="primary"
            @click="$emit('export', selected)"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import FIELDS_GROUP_BY from '@/enums/fieldsGroupBy';
import INCIDENT_FIELDS from '@/enums/incidentFields';
import PRESET_TYPES from '@/enums/presetTypes';
import FieldsSelector from '@/components/Incidents/FieldsSelector';
import PresetManager from '@/components/Incidents/Presets/PresetManager';
import { SET_EXPORT_PRESET } from '@/store/Incidents/mutations';

export default {
  components: { PresetManager, FieldsSelector },
  props: {
    type: {
      type: String,
      required: true,
    },
    allFields: {
      type: Array,
      required: true,
    },
    exporting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      search: null,
      groupBy: FIELDS_GROUP_BY.VALUES,
      selected: [],
      showHistoricalFields: false,
      fieldsGroupBy: FIELDS_GROUP_BY,
      incidentFields: INCIDENT_FIELDS,
      presetTypes: PRESET_TYPES,
    };
  },
  computed: {
    entity() {
      return this.$store.state.incidents[this.type];
    },
    presetData() {
      return {
        selected: this.selected,
        groupBy: this.groupBy,
        showHistoricalFields: this.showHistoricalFields,
      };
    },
  },

  watch: {
    dialog(visible) {
      if (visible) {
        this.selected = [];
        this.search = null;
        if (this.$refs.presetManager) {
          this.$refs.presetManager.set(null);
        }
      }
    },
  },

  methods: {
    setExportPreset(presets) {
      this.$store.commit(`incidents/${this.type}/${SET_EXPORT_PRESET}`, presets);
    },
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    setPreset(data) {
      if (data) {
        this.selected = _.clone(data.selected);
        this.groupBy = data.groupBy;
        this.showHistoricalFields = data.showHistoricalFields;
      } else {
        this.selected = [];
        this.groupBy = FIELDS_GROUP_BY.VALUES;
        this.showHistoricalFields = false;
      }
    },
    addPresetHandler(preset) {
      const presets = this.entity.exportPresets;
      presets.push(preset);
      this.setExportPreset(presets);
    },
    deletePresetHandler(presetIndex) {
      const presets = _.clone(this.entity.exportPresets);
      presets.splice(presetIndex, 1);
      this.setExportPreset(presets);
    },
    updatePresetHandler(index, preset) {
      const presets = _.clone(this.entity.exportPresets);
      presets[index] = preset;
      this.setExportPreset(presets);
    },
  },
};
</script>
