import {
  LOAD_EXPORT_FIELDS, LOAD_EXPORT_PRESETS,
  EXPORT_INCIDENTS, EXPORT_INCIDENTS_TO_EMAIL,
} from '@/store/Incidents/actions';

export default {
  data() {
    return {
      exporting: false,
      searchFilters: null,
      loadingExportPopup: false,
      directExportLimit: 10000,
      maxExportTimeMin: 7,
    };
  },

  methods: {
    loadExportFields() {
      return this.$store.dispatch(`incidents/${this.type}/${LOAD_EXPORT_FIELDS}`);
    },

    loadExportPresets() {
      return this.$store.dispatch(`incidents/${this.type}/${LOAD_EXPORT_PRESETS}`);
    },

    exportIncidents(payload) {
      return this.$store.dispatch(`incidents/${this.type}/${EXPORT_INCIDENTS}`, payload);
    },
    exportIncidentsToEmail(payload) {
      return this.$store.dispatch(`incidents/${this.type}/${EXPORT_INCIDENTS_TO_EMAIL}`, payload);
    },

    async showExport() {
      if (this.exportFields.length === 0 || this.exportPresets === null) {
        this.loadingExportPopup = true;
        try {
          await Promise.all([
            this.loadExportFields(),
            this.loadExportPresets(),
          ]);
        } finally {
          this.loadingExportPopup = false;
        }
      }
      this.$refs.selectiveExport.show();
    },

    async exportResult(selectedFields) {
      try {
        if (!this.checkOpportunityExport(selectedFields)) {
          throw Error('No opportunity of export');
        }

        this.exporting = true;
        if (this.totalItems >= this.directExportLimit) {
          this.$refs.confirmationPopup.showConfirm(
            'Warning',
            'Because this export contains > 10k rows of data, this report will take ~1 minute to produce. '
              + 'Once the export has been completed, you will receive an email with a link to download the file.',
            async () => {
              await this.exportIncidentsToEmail({
                type: this.type,
                selectedFields,
                searchFilters: this.searchFilters,
              });
            },
          );
        } else {
          await this.exportIncidents({
            type: this.type,
            selectedFields,
            searchFilters: this.searchFilters,
          });
        }
      } catch (e) {
        if (e.message === 'No opportunity of export') {
          this.$refs.exportErrorMessagePopup.show(
            'Export Size Exceeded',
            `It seems you are trying to export an XSLX file that might take more than ${this.maxExportTimeMin}`
              + ' minutes to process. Due to system limitations, please reduce your row number before attempting '
              + 'to export your selection. <br><br> One suggestion includes reducing the date range '
              + '(splitting it by year, and/or quarter) and trying again.',
            true,
          );
        }
      } finally {
        this.$refs.selectiveExport.close();
        this.exporting = false;
      }
    },

    /**
     * Check opportunity of export.
     *
     * @param selectedFields List of selected fields
     *
     * @returns {boolean}
     */
    checkOpportunityExport(selectedFields) {
      const countOfFields = (selectedFields === undefined || selectedFields.length === 0)
        ? 1
        : selectedFields.length;
      const averageSpeed = this.calculateSpeed(countOfFields);

      return (this.totalItems / averageSpeed) < this.maxExportTimeMin * 60;
    },

    /**
     * This method calculates average speed for incident export. The calculating makes
     * the method of linear interpolation. Intervals of calculating for linear interpolation
     * got method of testing different intervals, count of records and timing of execution.
     *
     * @param {Number} providedCountFields Count of fields in request
     *
     * @returns {Number}
     */
    calculateSpeed(providedCountFields) {
      const countsSpeeds = [
        { fields: 1, speed: 2836 },
        { fields: 106, speed: 801 },
        { fields: 211, speed: 369 },
        { fields: 422, speed: 199 },
        { fields: 845, speed: 109 },
      ];

      let countFields;

      if (providedCountFields < countsSpeeds[0].fields) {
        countFields = countsSpeeds[0].fields;
      } else if (providedCountFields > countsSpeeds[4].fields) {
        countFields = countsSpeeds[4].fields;
      } else {
        countFields = providedCountFields;
      }

      const element = countsSpeeds.find((elem) => countFields === elem.fields);

      if (element !== undefined) {
        return element.speed;
      }

      const elementLessThanCountFields = countsSpeeds.find(
        (elem, index, array) => countFields > elem.fields && countFields < array[index + 1].fields,
      );
      const elementGreaterThanCountFields = countsSpeeds.find(
        (elem, index, array) => countFields < elem.fields && countFields > array[index - 1].fields,
      );

      const speed = elementLessThanCountFields.speed
          - (elementLessThanCountFields.speed - elementGreaterThanCountFields.speed)
          * ((countFields - elementLessThanCountFields.fields)
              / (elementGreaterThanCountFields.fields - elementLessThanCountFields.fields));

      return Math.round(speed);
    },
  },
};
