<template>
  <v-card
    class="incidents-search"
    :loading="loading"
    flat
  >
    <v-container fluid>
      <v-toolbar
        flat
        dense
      >
        <v-subheader class="pl-0">
          <span class="title">Search</span>
        </v-subheader>
        <v-spacer />
        <v-btn
            :disabled="loading"
            small
            text
            color="blue"
            class="mr-5"
            @click="reset"
        >
          <v-icon left>
            mdi-autorenew
          </v-icon>
          Reset
        </v-btn>

        <v-btn
            :disabled="loading"
            small
            text
            color="primary"
            @click="advanced = !advanced"
        >
          <v-icon
              small
              color="primary"
          >{{ !advanced ? 'mdi-menu-down-outline' : 'mdi-menu-up-outline' }}</v-icon>
          Advanced Search
          <template v-if="totalAppliedFilters">
            ({{ totalAppliedFilters }} filters applied)
          </template>
        </v-btn>
      </v-toolbar>

      <v-row no-gutters>
        <v-col sm="10">
          <search-basic
              ref="basicSearch"
              :disabled="loading"
              :filters="entity.basicSearchFilters[type]"
              :show-status="type === searchTypes.FLAGGED"
              :show-date-removed="type === searchTypes.DELETED"
              :show-device="type === searchTypes.UNFINISHED"
              @filtersUpdate="basicSearch"
          />
        </v-col>
        <v-col sm="2">
          <v-btn
              :disabled="loading"
              :loading="searching"
              class="float-right mt-5"
              color="primary"
              @click="$refs.advancedSearch.search()"
          >
            <v-icon>search</v-icon>
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-expand-transition>
      <search-advanced
          v-show="advanced"
          ref="advancedSearch"
          :type="type"
          :loading="false"
          :show-save-monitor-btn="showSaveMonitor"
          :show-create-monitor-btn="showCreateMonitor"
          :show-find-duplications-btn="canFindDuplications"
          @search="advancedSearch"
          @save-monitor="saveMonitor"
          @create-monitor="createMonitor"
      />
    </v-expand-transition>
    <confirmation ref="confirmationPopup"/>
  </v-card>
</template>
<script>
import _debounce from 'lodash/debounce';
import { SET_BASIC_SEARCH_FILTERS, SET_ADVANCED_SEARCH_FILTERS, SET_SHOW_ADVANCED } from '@/store/Incidents/mutations';
import SearchBasic from '@/components/Incidents/SearchBasic';
import SEARCH_TYPES from '@/enums/searchTypes';
import SearchAdvanced from '@/components/Incidents/SearchAdvanced';
import Confirmation from '@/components/Confirmation';

export default {
  name: 'Search',
  components: { Confirmation, SearchAdvanced, SearchBasic },
  props: {
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searching: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showDateRemoved: {
      type: Boolean,
      default: false,
    },
    showSaveMonitor: {
      type: Boolean,
      default: false,
    },
    showCreateMonitor: {
      type: Boolean,
      default: false,
    },
    canFindDuplications: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounceFunc: null,
      searchTypes: SEARCH_TYPES,
    };
  },
  computed: {
    entity() {
      return this.$store.state.incidents[this.type];
    },
    advanced: {
      get() {
        return this.entity.showAdvanced;
      },
      set(value) {
        this.setShowAdvanced(value);
      },
    },
    totalAppliedFilters() {
      let count = 0;
      this.entity.advancedSearchFilters.forEach(group => {
        count += group.conditions.length;
      });
      return count;
    },
  },
  methods: {
    setBasicSearchFilters(payload) {
      this.$store.commit(`incidents/${this.type}/${SET_BASIC_SEARCH_FILTERS}`, payload);
    },
    setAdvancedSearchFilters(filters) {
      this.$store.commit(`incidents/${this.type}/${SET_ADVANCED_SEARCH_FILTERS}`, filters);
    },
    setShowAdvanced(value) {
      this.$store.commit(`incidents/${this.type}/${SET_SHOW_ADVANCED}`, value);
    },
    saveMonitor(filters) {
      this.$emit('save-monitor', filters);
    },
    createMonitor(filters) {
      this.$emit('create-monitor', filters);
    },
    basicSearch(filters, updateData = true) {
      this.setBasicSearchFilters({
        type: this.type,
        filters,
      });
      if (!this.debounceFunc) {
        const that = this;
        this.debounceFunc = _debounce(() => {
          that.$emit('searchBasic');
        }, 300);
      }
      if (updateData) {
        this.debounceFunc();
      }
    },
    setBasicFilters(filters, updateData) {
      this.$refs.basicSearch.setFilters(filters, updateData);
    },
    setConditions(conditions) {
      this.$refs.advancedSearch.setConditions(conditions);
    },
    advancedSearch(filters) {
      this.setAdvancedSearchFilters(filters);
      this.$emit('searchAdvanced');
    },
    reset() {
      this.$refs.confirmationPopup.showConfirm(
        'Confirmation',
        'Do you really want to reset search?',
        () => {
          this.$refs.basicSearch.reset();
          this.$refs.advancedSearch.reset();
        },
      );
    },
  },
};
</script>
<style lang="scss">
.incidents-search {
  .v-toolbar__content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
