<template>
  <v-data-table
    ref="dataTable"
    :headers="headers"
    :items="items"
    :options.sync="paginationOptions"
    :server-items-length="totalItems"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [10,25,50]
    }"
    :height="tableHeight"
    :class="this.$style['device-table']"
    fixed-header
  >
    <template v-slot:body="{ items }">
      <tbody>
        <NoDataAvailable
          :headers="headers"
          :items="items"
        />
        <v-hover
          v-for="item in items"
          :key="item.registryId"
        >
          <tr
            :class="[$style.tableRow, (item.registry.deleted ? $style.deleted : null)]"
          >
            <td>{{ item.dispatchId }}</td>
            <td>
              <a
                @click="item.totalNumberOfReportsLeftOnDevice
                  ? $emit('clickUnfinishedForms', item)
                  : null"
              >
                <v-badge
                  :color="item.totalNumberOfReportsLeftOnDevice <= 5 ? 'blue' : 'red'"
                >
                  <template slot="badge">
                    <span>{{ item.totalNumberOfReportsLeftOnDevice || 0 }}</span>
                  </template>
                </v-badge>
              </a>
            </td>
            <td>{{ item.lastSync | datetimeToClientTimezone }}</td>
            <td>{{ item.inventoryNumber }}</td>
            <td>{{ item.iosVersion }}</td>
            <td>{{ item.appVersion }}</td>
            <td>{{ item.templateName }}</td>
            <td>{{ item.BLSAllowed | blsLocked }}</td>
            <td>{{ item.unitType | unitType }}</td>
            <td>{{ item | isAPDevice}}</td>
            <td :class="[$style.actions, 'text-right', 'no-wrap']">
                <v-icon
                  v-if="!item.registry.deleted"
                  color="grey darken-2"
                  class="mr-3"
                  @click="$emit('clickEdit', item)"
                >
                  edit
                </v-icon>
                <v-icon
                  v-if="!item.registry.deleted"
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickDelete', item)"
                >
                  delete
                </v-icon>
                <v-icon
                  v-else
                  class="mr-3"
                  color="grey darken-2"
                  @click="$emit('clickRestore', item)"
                >
                  restore
                </v-icon>
            </td>
          </tr>
        </v-hover>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import fixedHeader from '@/mixins/fixedHeader';
import DEVICE_TYPE_NAMES from '@/enums/deviceTypeNames';
import NoDataAvailable from '../NoDataAvailable';

export default {
  name: 'DevicesTable',

  filters: {
    unitType(type) {
      const map = {
        A: DEVICE_TYPE_NAMES.ALS,
        B: DEVICE_TYPE_NAMES.BLS,
        H: DEVICE_TYPE_NAMES.HELI,
      };
      return map[type];
    },
    blsLocked(blsLocked) {
      return blsLocked ? 'Yes' : 'No';
    },
    isAPDevice(device) {
      return device.isAPDevice && device.isAllowedAP ? 'Yes' : 'No';
    },
  },
  components: {
    NoDataAvailable,
  },
  mixins: [fixedHeader],

  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      headers: [
        {
          text: this.$t('devices.tableHeaders.dispatchId'),
          value: 'dispatchId',
        },
        {
          text: this.$t('devices.tableHeaders.formsCount'),
          value: 'totalNumberOfReportsLeftOnDevice',
        },
        {
          text: this.$t('devices.tableHeaders.lastSync'),
          value: 'lastSync',
        },
        {
          text: this.$t('devices.tableHeaders.inventoryNum'),
          value: 'inventoryNumber',
        },
        {
          text: this.$t('devices.tableHeaders.iosVersion'),
          value: 'iosVersion',
        },
        {
          text: this.$t('devices.tableHeaders.appVersion'),
          value: 'appVersion',
        },
        {
          text: this.$t('devices.tableHeaders.template'),
          value: 'templateName',
          sortable: false,
        },
        {
          text: this.$t('devices.tableHeaders.blsLocked'),
          value: 'BLSAllowed',
        },
        {
          text: this.$t('devices.tableHeaders.unitType'),
          value: 'unitType',
        },
        {
          text: this.$t('devices.tableHeaders.isAPDevice'),
          value: 'isAPDevice',
        },
        {
          sortable: false,
        },
      ],
    };
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
};
</script>

<style lang="scss" module>
.nowrap {
  white-space: nowrap;
}

.deleted {
  background-color: $red-light;
}

.actions {
  width: 18%;
}

.tableRow .actions > button {
  visibility: hidden;
}

.tableRow:hover .actions > button {
  visibility: visible;
}

.device-table table thead tr th {
  z-index: 1 !important;
  width: 15%;
}
</style>
