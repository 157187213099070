import api from './api';

export default {
  /**
   * Create document to sign
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {String} password - Current user's password
   *
   * @returns Promise<Object>
   */
  createDocumentToSign(incidentId, password) {
    return api()
      .post(`client/incidents/${incidentId}/blood-transfusion/sign`, {
        password,
      })
      .then(response => response.data);
  },

  /**
   * Set signed status for document.
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {Number} signatureId - Identifier of incident
   *
   * @returns Promise<Object>
   */
  setSigned(incidentId, signatureId) {
    return api()
      .post(`client/incidents/${incidentId}/blood-transfusion/${signatureId}/set-signed`)
      .then(response => response.data);
  },
};
