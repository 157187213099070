var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"incident-bt-reports-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [10,25,50]
      },"hide-default-header":"","fixed-header":"","no-data-text":"No Blood Transfusion reports match the specified search criteria."},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{staticClass:"fixed sections"},[_c('th',{staticClass:"text-center sequence-number",attrs:{"rowspan":"2"}},[_vm._v(" SequenceNumber ")]),_c('th',{staticClass:"text-center",attrs:{"colspan":8}},[_vm._v(" Incident Info ")])]),_c('tr',{staticClass:"fields"},_vm._l((headers.filter(function (value) {
                return value.hidden !== true;
              })),function(header){return _c('th',{key:header.text,staticClass:"field-col text-left",class:[
                header.sortable ? 'sortable' : '',
                header.value === _vm.pagination.sortBy[0] ? 'active' : '',
                header.align === 'left' ? 'text-left' : '',
                _vm.pagination.sortDesc[0] ? 'desc': 'asc'
              ],style:(header.width ? 'min-width: ' + header.width + 'px;' : null),on:{"click":function($event){header.sortable ? _vm.changeSort(header.value) : null}}},[_c('span',{domProps:{"innerHTML":_vm._s(header.text)}}),(header.sortable)?_c('v-icon',{staticClass:"v-data-table-header__icon",attrs:{"small":""}},[_vm._v("mdi-arrow-up")]):_vm._e()],1)}),0)])]}},{key:"body.append",fn:function(){return [_c('tr')]},proxy:true},{key:"item.sequenceNumber",fn:function({ item }){return [_c('div',{staticClass:"first-col"},[_c('v-btn',{staticClass:"float-left mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.showIncidentForm(item)}}},[_c('v-icon',[_vm._v("mdi-file-image")])],1),_c('span',{staticClass:"float-left mt-2"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.INCIDENT_VIEW, params: { id: item.id }}}},[_c('b',[_vm._v(_vm._s(item.sequenceNumber))])])],1)],1)]}},{key:"item.incidentDateTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.incidentDateTime))+" ")]}},{key:"item.age",fn:function({ item }){return [_vm._v(" "+_vm._s(item.age)),(item.ageUnit !== null)?_c('span',[_vm._v(" "+_vm._s(item.ageUnit))]):_vm._e()]}},{key:"item.complaints",fn:function({ item }){return [(item.complaints.length !== 0)?_c('span',[_vm._v(" "+_vm._s(item.complaints.join(', ')))]):_vm._e()]}}])}),_c('view-incident-popup',{ref:"viewIncidentPopup",attrs:{"show-flag-btn":false,"show-nav-btn":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }